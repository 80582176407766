// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'

import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Name from '../form-fields/name'
import Email from '../form-fields/email'
import Phone from '../form-fields/phone-without-dialcodes'
import Comment from '../form-fields/comment'
import Submit from '../form-fields/submit'

import onSubmit from '../form-methods/on-submit'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BugReportForm */
class BugReportForm extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.formRef = this.props.refs.formRef
  }

  /** standard renderer */
  render() {
    const {
      onFinish,
      awaitingServerResponse,
      finishedCycle,
      serverMesage,
      refs,
    } = this.props
    const { formRef } = refs

    return (
      <Fragment>
        <Form
          id="bug-report"
          className="xform bug-report-form as-paragraph"
          scrollToFirstError
          ref={formRef}
          onFinish={onFinish}
        >
          <Name {...this.props} />
          <Email {...this.props} />
          <Phone {...this.props} />
          <Comment {...this.props} />
          <Submit
            {...this.props}
            serverMesage={serverMesage}
            finishedCycle={finishedCycle}
            awaitingServerResponse={awaitingServerResponse}
            buttonText="Submit"
            form="bug-report"
          />
        </Form>
        {isUndefined(serverMesage) === false && (
          <Fragment>
            <p
              style={{
                marginBottom: '0.2rem',
              }}
            >
              <strong>Registering your request</strong>
            </p>
            <p>{serverMesage}</p>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------- Compose & Exports
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedBugReportForm = compose(onSubmit)(BugReportForm)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedBugReportForm
